import React, { useEffect, useRef, useState } from 'react';
import Lottie from 'react-lottie';
import './CardGallery.css';
import {CardGalleryItem} from './types';
import {Button, Grid, Typography} from "@mui/material";

export interface CardGalleryProps {
    title: string[];
    buttonLabel: string;
    items: CardGalleryItem[];
    buttonClickHandler?: () => void;
}

export const CardGallery = ({ title, buttonLabel, items, buttonClickHandler }: CardGalleryProps) => {
    const galleryRef = useRef(null);
    const [hoveredCardIdx, setHoveredCardIdx] = useState<number | null>(null);

    const checkScrollPosition = () => {
        if (galleryRef.current) {
            const { scrollLeft, scrollWidth, clientWidth } = galleryRef.current;

            console.log("!!!ATTENTION...ATTENTION!!!");
        }
    };

    useEffect(() => {
        if (galleryRef.current) {
            // @ts-ignore
            galleryRef.current.addEventListener('scroll', checkScrollPosition);
        }

        return () => {
            if (galleryRef.current) {
                // @ts-ignore
                galleryRef.current.removeEventListener('scroll', checkScrollPosition);
            }
        };
    }, []);

    return (
        <Grid container>
            <Grid item xs={12}>
                <Grid container spacing={2} marginBottom={7}>
                    <Grid item xs={9} alignContent="center">
                        <Typography
                            sx={{
                                fontFamily: ['MonumentGrotesk-Medium', 'serif'].join(','),
                                fontSize: '3.5em',
                            }}
                        >
                            {title[0]}<br/>
                            {title[1]}
                        </Typography>
                    </Grid>
                    <Grid item xs={3} alignContent="center">
                        <Button
                            variant="contained"
                            size="large"
                            onClick={buttonClickHandler ? buttonClickHandler : () => {}}
                            sx={{
                                backgroundColor: '#0652FF',
                                color: '#FAFAFA',
                                ":hover": {
                                    backgroundColor: '#1B2030'
                                },
                                borderRadius: '22px',
                                fontFamily: ['MonumentGrotesk-Medium', 'serif'].join(',')
                            }}

                        >
                            {buttonLabel}
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <div ref={galleryRef} className="hide-scrollbar" style={galleryStyles}>
                    {items.map((item, idx) => (
                        <div
                            key={idx}
                            // @ts-ignore
                            style={{...cardStyles}}
                            onMouseEnter={() => setHoveredCardIdx(idx)}
                            onMouseLeave={() => setHoveredCardIdx(null)}
                        >
                            <div
                                // @ts-ignore
                                style={textContainerStyles}
                            >
                                <div
                                    // @ts-ignore
                                    style={textStyles}
                                >
                                    {item.title}
                                </div>
                            </div>
                            <Lottie
                                // @ts-ignore
                                style={lottieStyles}
                                options={{
                                    loop: true,
                                    autoplay: false,
                                    animationData: item.animationData,
                                }}
                                height={400}
                                width={400}
                                isStopped={hoveredCardIdx !== idx}
                                isPaused={false}
                                isClickToPauseDisabled={true}
                            />
                        </div>
                    ))}
                </div>
            </Grid>
        </Grid>
    );
};

const lottieStyles = {
    position: 'absolute',
    bottom: '15px',  // Adjust based on your card's padding for accurate placement
    left: '50%',
    transform: 'translateX(-50%)'
};


const textStyles = {
    color: 'white',
    textAlign: 'left',
    whiteSpace: 'normal',
    overflowWrap: 'break-word',
    fontSize: '1.15em'
};

const cardStyles = {
    width: '380px',
    height: '450px',
    background: '#7CA5FF',
    borderRadius: '20px',
    flexShrink: 0,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    padding: '10px',
    position: 'relative',
};

const textContainerStyles = {
    position: 'absolute',
    top: '20px',
    left: '40px',

};

const galleryStyles = {
    display: 'flex',
    gap: '15px',
    whiteSpace: 'nowrap',
    width: '100%',
    marginRight: '-100px',

    // Styles to hide the scrollbar
    // scrollbarWidth: 'none', // For Firefox
    // msOverflowStyle: 'none', // For Internet Explorer and Edge
};
