export interface PriceQuotes {
    [coinName: string]: number;
}
export interface ExchangeRates {
    [currencyName: string]: number;
}
export interface LTV {
    [coinName: string]: number[];
}
export interface LoanMetrics {
    LTV: LTV;
    exchangeRates: ExchangeRates;
    priceQuotes: PriceQuotes;
}

export interface CryptoPriceQuoteData {
    id: string;
    cryptoCurrency: string;
    usdPriceQuote: string;
}
export interface ListCryptoPriceQuotesResponse {
    data: {
        listCryptoPriceQuotes: {
            items: CryptoPriceQuoteData[]
        }
    }
}

export interface CurrencyExchangeRateData {
    id: string;
    currency: string;
    usdRate: string;
}
export interface ListCurrencyExchangeRatesResponse {
    data: {
        listCurrencyExchangeRates: {
            items: CurrencyExchangeRateData[];
        }
    }
}


export const convertPriceQuotes = (givenData: CryptoPriceQuoteData[]): PriceQuotes => {
    const returnable = givenData.reduce((accumulator, currentValue) => {
        accumulator[currentValue.cryptoCurrency] = Number(currentValue.usdPriceQuote);
        return accumulator;
    }, {} as PriceQuotes);

    return returnable;
};

export const convertExchangeRates = (givenData: CurrencyExchangeRateData[]): ExchangeRates => {
    const returnable = givenData.reduce((accumulator, currentValue) => {
        accumulator[currentValue.currency] = Number(currentValue.usdRate);
        return accumulator;
    }, {"USD": 1} as PriceQuotes);

    return returnable;
};