import React, {useEffect, useRef, useState} from 'react';
import ReactFullpage, {fullpageApi} from '@fullpage/react-fullpage';
import CountUp from 'react-countup';
import {Box, Container, Grid, Typography} from '@mui/material';
import {AutoPlayVideo} from '../../components/AutoPlayVideo';
import {Footer} from "../../components/Footer";
import {NavigationMenu} from "../../components/NavigationMenu";
import {CardGallery} from '../../components/cardGallery/CardGallery';
import {CardGalleryMobile} from "../../components/cardGallery/CardGalleryMobile";
import {DesktopLoanCalculator} from '../../components/loanCalculator/DesktopLoanCalculator';
import lottie1 from '../../animations/lottie1.json';
import lottie2 from '../../animations/lottie2.json';
import lottie3 from '../../animations/lottie3.json';
import lottie4 from '../../animations/lottie4.json';
import lottie5 from '../../animations/lottie5.json';
import lottie6 from '../../animations/lottie6.json';
import {BallsAnimation} from '../../animations/components/BallsAnimation';
import {MobileDesktopLoanCalculator} from "../../components/loanCalculator/MobileDesktopLoanCalculator";
import {useNavigate} from "react-router-dom";

export interface CategoryContentProps {
    title?: string;
    isCategoryOne?: boolean;
    isCategoryTwo?: boolean;
    isCategoryThree?: boolean;
    isCategoryFour?: boolean;
    isCategoryFive?: boolean;
    isCategorySix?: boolean
    fullpageApi: fullpageApi;
}

const CategoryContent = ({
     title,
     isCategoryOne,
     isCategoryTwo,
     isCategoryThree,
     isCategoryFour,
     isCategoryFive,
     isCategorySix,
     fullpageApi
 }: CategoryContentProps) => {
    const threejsContainerRef = useRef(null);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const [loanedAmount, setLoanedAmount] = useState(150603.02);
    const thisIsUsGalleyItems = [
        { title: "Fast track loans with no backtrack needed.", animationData: lottie1},
        { title: "All major crypto assets accepted.", animationData: lottie2 },
        { title: "Flexible return periods, customize your loan.", animationData: lottie3 },
    ];

    const navigate = useNavigate();
    const thisIsUsTitleButtonHandler = (): void => {
        // If you want to load/open the URL in the current tab:
        // window.location.href = 'https://www.youtube.com/';

        // If you want to load/open the URL in a new tab:
        // window.open('https://www.youtube.com/', '_blank');

        // If you want to navigate to another 'internal' route:
        navigate('/login');
    };

    const yourBenefitsGalleyItems = [
        { title: "Create an account and start borrowing.", animationData: lottie4 },
        { title: "Easy and secure checkout process.", animationData: lottie5 },
        { title: "Fast transfers in your fiat account.", animationData: lottie6 },
    ];

    useEffect(() => {
        if (isCategoryTwo) {
            const interval = setInterval(() => {
                const randomIncrease = Math.random() * (400 - 30) + 30;
                setLoanedAmount(prevAmount => prevAmount + randomIncrease);
            }, 10000);

            return () => clearInterval(interval);
        }
    }, [isCategoryTwo]);

    useEffect(() => {
        const handleResize = () => setIsMobile(window.innerWidth <= 768);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    BallsAnimation(threejsContainerRef, isCategorySix, isMobile);

    const containerStyle = {
        ...((isCategoryThree || isCategoryFour) ? styles.containerCategoryThree : styles.container),
        width: isMobile ? '90%' : '70%',
    };

    const categoryThreeStyle = {
        ...styles.containerCategoryThree,
        width: isMobile ? '80%' : '100%',
        margin: '0 auto',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    };

    return (
        <div style={isCategoryThree || isCategoryFour ? categoryThreeStyle : containerStyle}>
            <Container>
                <Grid container spacing={2}>
                    {isCategoryOne && (
                        <>
                            <Grid item xs={12} md={5} style={{ marginTop: '30px' }}>
                                <Box display="flex" justifyContent="center" alignItems="center">
                                    <Box>
                                        <Typography
                                            fontSize={isMobile ? 'x-large' : 'xx-large'}
                                            justifyContent="left"
                                            sx={{
                                                fontFamily: ['MonumentGrotesk-Medium', 'serif'].join(','),
                                                justifySelf: 'left',
                                                color: '#FAFAFA',
                                            }}
                                        >
                                            Borrow Cash against your Crypto Assets
                                        </Typography>
                                    </Box>
                                </Box>
                                {
                                    isMobile ? (
                                        <MobileDesktopLoanCalculator
                                            fullpageApi={fullpageApi}
                                            sliderColorOverride="#1B2030"
                                            sliderTextColorOverride="#1B2030"
                                        />
                                    ) : (
                                        <DesktopLoanCalculator fullpageApi={fullpageApi}/>
                                    )
                                }
                            </Grid>
                            {
                                !isMobile && (
                                    <Grid item xs={12} md={7}>
                                        <div style={{ width: '100%', height: '400px', overflow: 'hidden'}}>
                                            <AutoPlayVideo height={400} loop playsInline muted/>
                                        </div>
                                    </Grid>
                                )
                            }
                        </>
                    )}
                    {isCategoryTwo && (
                        <Grid container style={{maxHeight: '100vh', alignItems: 'center', justifyContent: 'center', overflow: 'auto'}}>
                            <Grid item xs={12}> {/* Ensure item takes full width */}
                                <div
                                    // @ts-ignore
                                    style={styles.flexContainer}
                                >
                                    <div style={{fontWeight: 'bold'}}>
                                        <h1 style={{color: '#1B2030', fontSize: isMobile ? '2.5em' : '3.5em'}}>Our users loaned</h1>
                                        <h1 style={{color: '#0752FF', fontSize: '3.5em'}}>
                                            <CountUp
                                                start={loanedAmount - 400} // Optional: Starting from slightly less for immediate visual effect
                                                end={loanedAmount}
                                                decimals={2}
                                                prefix="€"
                                                duration={2.5} // Duration for the count up animation
                                            />
                                        </h1>
                                        <h4 style={{color: '#1B2030', fontSize: '1.2em'}}>since we opened Bitlendr.io</h4>
                                        <h4 style={{color: '#1B2030', fontSize: '1.2em'}}>across 3 networks and over 7 markets.</h4>
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                    )}
                    {isCategoryThree && (
                        <>
                            {isMobile ? (
                                // Mobile-specific content for Section 3
                                <CardGalleryMobile
                                    title='Transparent and accessible instant cash loans.'
                                    label='Get money'
                                    labelClickHandler={thisIsUsTitleButtonHandler}
                                    items={thisIsUsGalleyItems}
                                />
                            ) : (
                                <CardGallery
                                    title={["Transparent and accessible", "instant cash loans."]}
                                    buttonLabel='Get money'
                                    buttonClickHandler={thisIsUsTitleButtonHandler}
                                    items={thisIsUsGalleyItems}
                                />
                            )}
                        </>
                    )}
                    {isCategoryFour && (
                        <>
                            {isMobile ? (
                                // Mobile-specific content for Section 4
                                <CardGalleryMobile
                                    title='Use crypto as collateral for your credit needs.'
                                    label='Start now'
                                    items={yourBenefitsGalleyItems}
                                />
                            ) : (
                                <CardGallery
                                    title={["Use crypto as collateral","for your credit needs."]}
                                    buttonLabel='Start now'
                                    items={yourBenefitsGalleyItems}
                                />
                            )}
                        </>
                    )}
                    {isCategoryFive && (
                        <>
                            <Grid item xs={12} md={12}>
                                <Box display="flex" justifyContent="center" mb={8}>
                                    {/*<h1 className="section1Text">What you see is what you get.</h1>*/}
                                    <Typography
                                        fontSize="xxx-large"
                                        sx={{
                                            fontFamily: ['MonumentGrotesk-Medium', 'serif'].join(','),
                                            justifyContent: 'center'
                                        }}
                                    >
                                        What you see <br/> is what you get.
                                    </Typography>
                                </Box>
                                <DesktopLoanCalculator
                                    fullpageApi={fullpageApi}
                                    inputFieldBackgroundColorOverride="#FAFAFA"
                                    iconColorOverride="#1B2030"
                                    sliderColorOverride="#1B2030"
                                    sliderTextColorOverride="#1B2030"
                                    buttonTextOverride='Get your loan now →'
                                />
                            </Grid>
                        </>
                    )}
                    {isCategorySix && (
                        <>
                            <div
                                ref={threejsContainerRef}
                                // @ts-ignore
                                style={{...styles.animationContainer}}
                            />
                            <h1
                                // @ts-ignore
                                style={{...styles.textOnTop, fontSize: isMobile? '2.5em': '4em'}}
                            >
                                We accept all major crypto assets.
                            </h1>
                        </>
                    )}
                </Grid>
            </Container>
            <h3>{title}</h3>
        </div>
    );
};

const styles = {
    flexContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        width: '100%', // Ensure it takes full width
    },
    container: {
        maxHeight: '100vh',
        width: '70%',
        margin: '0 auto',
        padding: '20px 0',
        display: 'flex',
        justifyContent: 'center',
    },
    containerCategoryThree: {
        maxHeight: '100vh',
        width: '85%',
        margin: '0 0 0 auto',
        padding: '20px 0',
    },
    section1Spam: {
        position: 'absolute',
        bottom: 0,
        left: 0,
        width: '100%',
        paddingLeft: '15%',
        color: 'white',
    },
    animationContainer: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
    },
    textOnTop: {
        color: '#1B2030',
        maxWidth: '70%',
        textAlign: 'center',
        marginLeft: '20%',
        marginBottom: '30px',
        zIndex: 2,
    },
};
export const LandingPage = () => {
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    useEffect(() => {
        const handleResize = () => setIsMobile(window.innerWidth <= 768);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <ReactFullpage
            credits={{enabled: false, position: 'left', label: ''}}
            licenseKey={'YOUR_KEY'}
            scrollingSpeed={1000}
            render={({state, fullpageApi}) => {
                return (
                    <>
                        <ReactFullpage.Wrapper>
                            <div className="section" style={{backgroundColor: '#000BFF', height: '50vh'}}>
                                <NavigationMenu/>
                                <CategoryContent isCategoryOne fullpageApi={fullpageApi}/>
                            </div>
                            <div className="section" style={{backgroundColor: '#E6ECFB', maxHeight: '100vh'}}>
                                <CategoryContent isCategoryTwo fullpageApi={fullpageApi}/>
                            </div>
                            <div className="section" style={{backgroundColor: '#E6ECFB'}}>
                                <CategoryContent isCategoryThree fullpageApi={fullpageApi}/>
                            </div>
                            <div className="section" style={{backgroundColor: '#E6ECFB'}}>
                                <CategoryContent isCategoryFour fullpageApi={fullpageApi}/>
                            </div>
                            {
                                isMobile ? null : (
                                    <div className="section" style={{backgroundColor: '#E6ECFB'}}>
                                        <CategoryContent isCategoryFive fullpageApi={fullpageApi}/>
                                    </div>
                                )
                            }
                            <div className="section" style={{backgroundColor: '#E6ECFB'}}>
                                <CategoryContent isCategorySix fullpageApi={fullpageApi}/>
                            </div>
                            <div className="section bgc5 fp-auto-height">
                                <Footer/>
                            </div>
                        </ReactFullpage.Wrapper>
                    </>
                );
            }}
        />
    );
};
