import React, {useEffect, useState} from 'react';
import {BrowserRouter, Navigate, Route, Routes} from 'react-router-dom';
import {Login} from './pages/login/Login';
import {LandingPage} from './pages/landingPage/LandingPage';
import {Home} from './pages/home/Home';
import {LoanList} from './pages/loanList/LoanList';
import {OnboardPage} from './pages/onboard/OnboardPage';
import {ReviewPage} from './pages/review/ReviewPage';
import {ReceiptPage} from './pages/receipt/ReceiptPage';
import {SettingsPage} from './pages/settings/SettingsPage';
import {SignupPage} from './pages/signup/SignupPage';
import Terms from './pages/terms/Terms';
import Policy from './pages/policy/Policy'
import {ForgotPasswordPage} from './pages/forgotPassword/ForgotPasswordPage';

import { Amplify } from 'aws-amplify';
import { Authenticator, View } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import config from './amplifyconfiguration.json';
import { AuthUser, getCurrentUser } from 'aws-amplify/auth';
import { Hub } from 'aws-amplify/utils';
Amplify.configure(config);

export const App = () => {
  const [user, setUser] = useState<AuthUser | null>(null);
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [error, setError] = useState<unknown>(null);

  useEffect(() => {
    setIsAuthenticated(user !== null);
  }, [user]);

  useEffect(() => {
    const unsubscribe = Hub.listen("auth", ({ payload }) => {
      switch (payload.event) {
        case "signedIn":
          getUser();
          break;
        case "signInWithRedirect":
          getUser();
          break;
        case "signInWithRedirect_failure":
          const newError = "An error has occurred during the OAuth flow.";
          console.error(`New error: ${newError}`);
          setError(newError);
          break;
      }
    });

    getUser();

    return unsubscribe;
  }, []);

  const getUser = async (): Promise<void> => {
    try {
      const currentUser = await getCurrentUser();
      setUser(currentUser);
      console.log(`Current user is: ${JSON.stringify(currentUser)}`)
    } catch (error) {
      console.error(`Encountered error getting user info. Error is [${error}].`);
      console.log("Not signed in");
    }
  };

  return (
      <Authenticator.Provider>
        <View>
          <BrowserRouter>
            <Routes>
              <Route path="/" element={
                isAuthenticated
                    ? <Home/>
                    : <LandingPage/>
              }/>
              <Route path="/home" element={
                isAuthenticated
                    ? <Home/>
                    : <Navigate to="/login" replace/>
              }/>
              <Route path="/onboard" element={
                isAuthenticated
                    ? <OnboardPage/>
                    : <Navigate to="/login" replace/>
              }/>
              <Route path="/review" element={
                isAuthenticated
                    ? <ReviewPage/>
                    : <Navigate to="/login" replace/>
              }/>
              <Route path="/receipt" element={
                isAuthenticated
                    ? <ReceiptPage/>
                    : <Navigate to="/login" replace/>
              }/>
              <Route path="/list" element={
                isAuthenticated
                    ? <LoanList/>
                    : <Navigate to="/login" replace/>
              }/>
              <Route path="/terms" element={<Terms/>} />
              <Route path="/policy" element={<Policy/>} />
              {/*<Route path="/crypto-checkout" element={*/}
              {/*  <CryptoCheckout/>*/}
              {/*}/>*/}
              {/*<Route path="/loan-list" element={*/}
              {/*  <LoanList/>*/}
              {/*}/>*/}
              {/*<Route path="/close-loan" element={*/}
              {/*  <CloseLoan/>*/}
              {/*}/>*/}
              <Route path="/settings" element={
                isAuthenticated
                    ? <SettingsPage/>
                    : <Navigate to="/login" replace/>
              }/>
              <Route path="/signup" element={<SignupPage />}/>
              <Route path="/login" element={isAuthenticated ? <Navigate to="/home" replace /> : <Login/>}/>
              <Route path="/forgot-password" element={<ForgotPasswordPage/>}/>
            </Routes>
          </BrowserRouter>
        </View>
      </Authenticator.Provider>
  );
};
